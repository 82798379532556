//Variables


//barvy
$blue: #003064;
$white: #FFF;
$red: #D1012D;
$hover-red: #A70024;
$grey: #F8F8F8;



//Fonts
@font-face {
    font-family: Exo-2;
    src: url("./fonts/Exo2-Regular.ttf");
}
p,a,h1,h2,h3,h4,h5,h6,strong,span {
    font-family: Exo-2;
    margin-bottom: 0;
}

//general


h1 {
  margin-top:43px;
  font-family: Exo-2;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
h3 {
  color: $blue;
  font-family: Exo 2;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;

text-align: center;
}

h4 {
  color: $blue;
  text-align: center;
  font-family: Exo-2;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.blue {
  color: $blue;
}
.white {
  color: $white;
}
.red {
  color: $red;
  a {
    color:$red;
  }
}
.container {
  margin: auto;
}
.right {
  float: right;
}
.left {
  text-align: left;
}
a {
  color: $blue;
  text-decoration: none;
}
ul {
  margin-bottom: 0;
  padding-bottom:39px;
}

//Header
.sticky-top {
  background-color: $blue;
  .top-header {
    .col {
      text-align: end;
    }
    padding-top:20px;
    span {
      color: $white;
      a {
        color: $white;
        text-decoration: none;
      }
      &.phone-number {
        vertical-align: bottom;
        opacity: 0.7;
        margin-right:18px;
      }
      &.icon:not(:last-child) {
        margin-right: 10px
      }
    }
  }
  .navbar-nav {
    margin-right:50px;
  }
}
.navbar {
  padding-top:0;
  background-color: $blue;
  color: $white;


  @media screen and (max-width: 991px) {
    .navbar-collapse {
      transition: height 0.3s ease-in-out;
      overflow: hidden;
      max-height: 1000px;
      height: 0;
    }
    .navbar-collapse.show {
      height: auto; /* Automatically adjust the height when expanded */
      max-height: none; /* Remove the max-height property */
    }
    .nav-link {
      text-align: center;
    }
  }
  .navbar-brand  {
    img {
    height: 85px;
    float: left;
    }
  }
  .nav-link {
    color: $white;
    font-size: 18px;

    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
    transition: 0.4s all ease-in-out;
    &:hover {
      color: $red;
    }
    &.active {
      color: black;
    }
    &.e-shop {
      border-radius: 3px;
      background-color: $red;
      padding: 10px 16px;
      margin-left:50px;
    }
  }
  .navbar-toggler {
    .navbar-toggler-icon {
    background-image: url('./svg/hamburger.svg');
    }
  }

}

// HP
.topheader {
  margin: 54px 0 34px;
}
.card {
  max-width: 553px;
  max-height: 264px;
  .card-title {
    color: $white;
    font-family: Exo 2;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 46px 0 0 48px;
  }
  .btn {
      margin: 104px 0 0 48px;
  }
}
.btn {
  display: inline-flex;
  background-color: $red;
  color: $white;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-family: Exo-2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
  &.move_right {
      gap:10px;
    &:after {
      content: url('./svg/move_right.svg');
      width: 24px;
      height: 24px;
    }
  }
  &.cart {
    gap:10px;
    &:before {
      content: url('./svg/cart.svg');
      width: 20px;
      height: 20px;
    }
  }
  &:hover,&:active {
    background-color: $hover-red!important;
    color: $white!important;
    border-color: transparent!important;
  }
}

//grey-text
.grey {
  margin-top:65px;
  background: $grey;
  .text-cards {
    margin: auto;
    .top-image {
      display: flex;
      margin: auto;
      margin-top: 31px;
      margin-bottom: 18px;
    }
    h4 {
      height: 68px;
    }
    .card-text {
      color: #838383;
  text-align: center;
height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom:40px;
    }
  }
}
.text-cards {
  background-color: $grey;
}

//Carousel
.carousel {
  margin-top: 46px;

  .carousel-item {
    h1.blue {
      position: absolute;
      top:10%;
      left:10%;
    }
     a {
      padding: 16.25px 26px;
      position: absolute;
      bottom:10%;
      right: 5%;
      &:after {
        content: '';
      }
    }
    .carousel-caption {
      position: initial;
    }
  }
}
.carousel-container {
  max-width: 1000px; /* Adjust as needed */
  margin: 0 auto;
}
.carousel-slide {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
}
.carousel-slide img {
  max-width: 100%;
  height: auto;

}
//partners HP
.carousel-root {
  .slide {
    img {
      width: auto;
      height: 50px;
      &.curver {
        height: 50px;
      }
      &.keter {
        height: 50px;
      }
      &.kvd {
        height: 50px;
      }
      &.substral {
        height: 50px;
      }
    }
  }
  .control-arrow {
    top:-20px!important;
  &:hover {
    background: transparent!important;
  }
  &:before {
    content: url('./svg/arrow.svg')!important;
  }
  &.control-next:before {
    transform: rotateY(180deg);
  }
 &.control-next {
       right:-35px;
 }
  }
}
.partner-carousel {
  margin-bottom:69px;
  margin-top:43px;
  h1 {
    margin-top:0;
  }
  .carousel-root {
    align-self:end;
  }
}
.about-us {
  display: flex;
  background-color: $blue;
  .container {
  }
  padding-bottom:59px;
  .row {
    margin-top:46px;
  }
  p {
    margin-bottom:21px;
  }
  .btn {
    margin-right: 31px;
  }
}
.footer {
  .phone,.mail {
    align-items: center;
    justify-content: center;
    margin-top:46px;
    .phone-icon,.envelope-icon {
      margin-right:29px;
    }
    p {
      .number,.mail {
        a {
          font-size: 32px;
          text-decoration: none;
        }
      }
    }
  }
  h3 {
    margin-top:67px;
  }
  .feeds {
    margin-top:43px;
    .facebook {
      height: 400px;
      overflow: hidden;
    }
    .instagram {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .copyright {
    margin-top:43px;
    text-align: center;
    margin-bottom: 43px;
  }

}
.prodejna {
  height: auto;
  width: 100%;
  border-radius: 3px;
}
.kontakt {
  margin-top:59px;
  margin-bottom:59px;
}
.Partneri-table {
  margin-top:39px;
  thead {
    background-color: $blue;
    color: white;
  }
  tbody {
    tr {
    border: 1px solid $grey;
    &.odd {
      background-color: $grey;
    }
    }
  }
  .zip {
    width: 100px;
  }
}
.container.map {
  border-radius: 3px;
  margin-top:39px;
  > div {
    margin-top: 20px;
  }
}

// O nás
.o-nas {
  h1  {
    font-size: 46px;
    margin-bottom: 39px;
  }
  p {
    margin-bottom: 8px;
  }
}

.partners {
  h1 {
    margin-bottom: 20px;
  }
  .partneri-table {
    table {
      tbody {
      border: 2px solid black;
      }
    }
  }
}
.carousel {
  .control-prev.control-arrow {
    left:-20px;
  }
  .control-prev.control-arrow:before,.control-arrow.control-next:before {
    border-right: 0;
    border-left: 0;
    margin-right: 20px;
  }
}
form {
  background-color: $blue;
  div {
    display: grid;
    p {
      padding: 25px;
      font-size: 18px;

    }
  }
  input,textarea {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-bottom: 1px solid white;
    background-color: $blue;
    color:white;
    padding: 5px;
    font-weight: bolder;
    height: 60px;
    &.comment {
      height: 180px;
    }
    &:hover,&:focus {
      border: 1px solid white;
      transition: 1s all;
    }
  }
  button {
    width: fit-content;
    margin: auto;
    margin-top: 25px;
    font-size: 18px;
    background-color: white;
    border: none;
    padding: 15px 25px;
  }
}
